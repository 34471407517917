import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  debounce,
  SelectChangeEvent,
  Drawer,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Snackbar,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import {
  Add,
  ExpandMore,
  ChevronRight,
  ArrowUpward,
  ArrowDownward,
  Delete
} from '@mui/icons-material';
import { Alert } from '@mui/material';
import { usePageState } from 'cms/hooks/usePageState';
import { useUpdatePageMutation } from 'cms/mutations/updatePage';
import { useGetComponentsQuery } from 'cms/queries/getComponents';
import { useGetPageQuery } from 'cms/queries/getPage';
import {
  Component,
  FileUploadPurpose,
  Page as PageType
} from 'common/types/graphql-types';
import { AuthRole, ComponentAttribute } from 'common/types/graphql-types';
import {
  FC,
  ChangeEvent,
  useMemo,
  Fragment,
  useState,
  useCallback,
  useEffect,
  useRef,
  MouseEvent as ReactMouseEvent
} from 'react';
import { CreatePageModal } from './CreatePageModal';
import { PageProps } from './Page';
import { useMarkFileAsPublicMutation } from 'cms/mutations/markFileAsPublic';
import { Upload, useUploadsStore } from 'common/state/uploads';
import { useMeQuery } from 'user/queries/me';

const MP3 = '.mp3';

interface State {
  drawerOpen: boolean;
  openEditor: string | null;
  contextMenu: {
    x: number;
    y: number;
  } | null;
  showCreate: boolean;
}

const initialState = {
  drawerOpen: false,
  openEditor: null,
  contextMenu: null,
  showCreate: false
};

export const PageEditor: FC<PageProps> = ({ slug }) => {
  const { data } = useGetPageQuery({ variables: { slug, isAdmin: true } });
  const { data: componentData } = useGetComponentsQuery();
  const [pageState, { startEditing, addComponent, stopEditing }] =
    usePageState();
  const [selectedComponent, setSelectedComponent] = useState<string | null>(
    null
  );
  const [updatePage] = useUpdatePageMutation();
  const { data: authData } = useMeQuery();
  const [state, updateState] = useState<State>(initialState);
  const { drawerOpen, openEditor, contextMenu } = state;

  const onContextMenu = useCallback(
    (event: MouseEvent) => {
      if (!authData?.me || authData.me.roles.indexOf(AuthRole.Admin) < 0) {
        return;
      }

      event.preventDefault();
      updateState({
        ...state,
        contextMenu: {
          x: event.pageX,
          y: event.pageY
        }
      });
    },
    [state]
  );

  useEffect(() => {
    document.addEventListener('contextmenu', onContextMenu);
    return () => {
      document.removeEventListener('contextmenu', onContextMenu);
    };
  }, [state]);

  useEffect(() => {
    if (!pageState) {
      updateState({
        ...state,
        drawerOpen: false,
        contextMenu: null
      });
    }
  }, [pageState]);

  const toggleCreateModal = useCallback(() => {
    updateState({
      ...state,
      showCreate: !state.showCreate
    });
  }, [state.showCreate]);

  const handleClose = useCallback(() => {
    updateState({
      ...state,
      contextMenu: null
    });
  }, [state]);

  const handleSelect = useCallback((evt: SelectChangeEvent<string>) => {
    setSelectedComponent(evt.target.value as string);
  }, []);

  const handleOpenEditor = useCallback(
    (editor: string) => (event: ChangeEvent<unknown>, isExpanded: boolean) => {
      updateState({
        ...state,
        openEditor: isExpanded ? editor : null
      });
    },
    [state]
  );

  const handleAddComponent = useCallback(() => {
    const component = componentData?.components?.find(
      (obj) => obj.component === selectedComponent
    );

    if (!component) {
      return;
    }
    addComponent(`content.${pageState?.content?.length}`, component);
  }, [componentData, selectedComponent, pageState]);

  const handleAddMetadata = useCallback(() => {
    const component = componentData?.components?.find(
      (obj) => obj.component === 'meta'
    );

    if (!component) {
      return;
    }
    addComponent(`head.${pageState?.head?.length}`, component);
  }, [componentData, pageState]);

  const toggleDrawer = useCallback(() => {
    updateState({
      ...state,
      contextMenu: null,
      drawerOpen: !state.drawerOpen
    });
  }, [state]);

  const handleStartEditing = useCallback(() => {
    if (!data) {
      return;
    }
    updateState({
      ...state,
      drawerOpen: true,
      contextMenu: null
    });
    startEditing(data.page);
  }, [state, data]);

  const cancel = useCallback(() => {
    stopEditing();
  }, [state]);

  const save = useCallback(async () => {
    if (!pageState) {
      return;
    }
    await updatePage({
      variables: { published: !!pageState?.publishedAt, ...pageState }
    });
    stopEditing();
  }, [state, pageState]);

  return (
    <>
      <CreatePageModal open={state.showCreate} onClose={toggleCreateModal} />
      <Menu
        open={!!contextMenu}
        onClose={handleClose}
        keepMounted={true}
        anchorReference='anchorPosition'
        anchorPosition={
          contextMenu ? { top: contextMenu.y, left: contextMenu.x } : undefined
        }
      >
        {pageState
          ? [
              <MenuItem key={'save'} onClick={save}>
                Save
              </MenuItem>,
              <MenuItem key={'cancel'} onClick={cancel}>
                Discard Changes
              </MenuItem>,
              <MenuItem key={'toggleDrawer'} onClick={toggleDrawer}>
                {drawerOpen ? 'Close Drawer' : 'Open Drawer'}
              </MenuItem>
            ]
          : [
              <MenuItem key={'create'} onClick={toggleCreateModal}>
                Create Page
              </MenuItem>,
              <MenuItem key={'edit'} onClick={handleStartEditing}>
                Edit Page
              </MenuItem>
            ]}
      </Menu>
      {authData?.me &&
        authData?.me.roles.indexOf(AuthRole.Admin) > 0 &&
        pageState?.content && (
          <Drawer open={drawerOpen} variant='persistent' anchor={'right'}>
            <Box width={400}>
              <Box padding={1} paddingLeft={2}>
                <IconButton onClick={toggleDrawer} size='large'>
                  <ChevronRight />
                </IconButton>
              </Box>
              <PageMetaEditor />

              <Box padding={1}>
                <Typography variant={'h4'}>Meta Tags</Typography>
              </Box>
              {(pageState?.head as Record<string, unknown>[]).map(
                (headerProps, i) => {
                  const key = `head.${i}`;
                  return (
                    <ComponentEditor
                      key={`${headerProps?.component}-${i}`}
                      deleteable={true}
                      path={key}
                      open={key === openEditor}
                      onChange={handleOpenEditor(key)}
                      content={headerProps}
                    />
                  );
                }
              )}
              <Box display={'flex'} justifyContent={'center'} padding={1}>
                <Button
                  onClick={handleAddMetadata}
                  color={'primary'}
                  variant={'contained'}
                >
                  Add Metadata
                </Button>
              </Box>
              <Box padding={1}>
                <Typography variant={'h4'}>Content</Typography>
              </Box>
              {(pageState?.content as Record<string, unknown>[]).map(
                (contentProps, i) => {
                  const key = `content.${i}`;
                  return (
                    <ComponentEditor
                      key={`${contentProps?.component}-${i}`}
                      sortable={true}
                      deleteable={true}
                      path={key}
                      open={key === openEditor}
                      onChange={handleOpenEditor(key)}
                      content={contentProps}
                    />
                  );
                }
              )}
              <Box paddingTop={2}>
                <Grid container={true} justifyContent={'center'} spacing={2}>
                  <Grid item={true} xs={11}>
                    <FormControl fullWidth={true}>
                      <Select
                        value={selectedComponent || ''}
                        onChange={handleSelect}
                      >
                        <MenuItem value={''}>Select Component</MenuItem>
                        {componentData?.components.map((component) => {
                          if (
                            !component ||
                            !data?.page?.allowedComponents ||
                            data?.page?.allowedComponents?.indexOf(
                              `${component.category}.${component.apiId}`
                            ) < 0
                          ) {
                            return null;
                          }
                          return (
                            <MenuItem
                              key={component.component}
                              value={component.component}
                            >
                              {component.component}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item={true} xs={6}>
                    <Button
                      onClick={handleAddComponent}
                      color={'primary'}
                      disabled={!selectedComponent}
                      variant={'contained'}
                    >
                      Add Component
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Drawer>
        )}
      {!data?.page?.publishedAt && (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={true}
        >
          <Alert severity={'warning'}>
            You are viewing a draft version of this page!
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export const PageMetaEditor = () => {
  const [pageState, { editComponent, stopEditing }] = usePageState();
  const [updatePage] = useUpdatePageMutation();
  const [localValues, setLocalValues] =
    useState<
      Pick<
        PageType,
        'backgroundImage' | 'backgroundOverlayImage' | 'title' | 'publishedAt'
      >
    >();

  useEffect(() => {
    if (!pageState) {
      return;
    }
    setLocalValues({
      title: pageState.title,
      publishedAt: pageState.publishedAt,
      backgroundImage: pageState.backgroundImage,
      backgroundOverlayImage: pageState.backgroundOverlayImage
    });
  }, []);

  const handleEditComponent = useRef(
    debounce((path: string, value: string | number | boolean) => {
      editComponent(path, value);
    }, 250)
  );

  const handleChange = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      const val =
        evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value;
      setLocalValues({
        ...localValues,
        [evt.target.name]: val
      });
      handleEditComponent.current(evt.target.name, val);
    },
    [localValues]
  );

  const handleSave = useCallback(async () => {
    if (!pageState) {
      return;
    }
    await updatePage({
      variables: { published: !!pageState.publishedAt, ...pageState }
    });
    stopEditing();
  }, [pageState]);

  if (!localValues) {
    return null;
  }

  return (
    <Box marginBottom={2}>
      <Grid
        container={true}
        spacing={2}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Grid item={true} xs={5}>
          <FormControlLabel
            control={
              <Switch
                name={'publishedAt'}
                onChange={handleChange}
                checked={!!localValues?.publishedAt}
                color='primary'
              />
            }
            label={'Published'}
          />
        </Grid>
        <Grid item={true} xs={6}>
          <Button
            onClick={handleSave}
            fullWidth={true}
            color={'primary'}
            variant={'contained'}
          >
            Save
          </Button>
        </Grid>
        <Grid item={true} xs={11}>
          <TextField
            name={'title'}
            value={localValues?.title || ''}
            onChange={handleChange}
            label={'Title'}
            fullWidth={true}
          />
        </Grid>
        <Grid item={true} xs={11}>
          <TextField
            name={'backgroundImage'}
            value={localValues?.backgroundImage || ''}
            onChange={handleChange}
            label={'Background Image'}
            fullWidth={true}
          />
        </Grid>
        <Grid item={true} xs={11}>
          <TextField
            name={'backgroundOverlayImage'}
            value={localValues?.backgroundOverlayImage || ''}
            onChange={handleChange}
            label={'Background Overlay'}
            fullWidth={true}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

interface ComponentEditorProps {
  open: boolean;
  path: string;
  sortable?: boolean;
  deleteable?: boolean;
  onChange?: (event: ChangeEvent<unknown>, isExpanded: boolean) => void;
  content: Record<string, unknown>;
}

export const ComponentEditor: FC<ComponentEditorProps> = ({
  onChange,
  open,
  path,
  sortable,
  deleteable,
  content: { component: contentComponent, ...content }
}) => {
  const { data: componentData } = useGetComponentsQuery();
  const [, { addComponent, moveComponent, removeComponent }] = usePageState();

  const component = useMemo(() => {
    const [collection, name] = ((contentComponent as string) || '').split('.');
    return componentData?.components.find(
      (comp) => name === comp.apiId && comp.category === collection
    );
  }, [content, componentData]);

  const createHandleInsertComponent = useCallback(
    (path: string, component: Component) => () => {
      addComponent(path, component);
    },
    []
  );

  const createHandleRemoveComponent = useCallback(
    (path: string) => () => {
      removeComponent(path);
    },
    []
  );

  const createHandleMoveComponent = useCallback(
    (path: string, dir: 'up' | 'down') =>
      (evt: ReactMouseEvent<HTMLElement>) => {
        evt.stopPropagation();
        const [arr, startStr] = path.split(/\.(?=[^\.]+$)/);
        const start = parseInt(startStr, 10);
        const end = dir === 'up' ? start - 1 : start + 1;
        moveComponent(arr, start, end);
      },
    []
  );

  return (
    <Accordion onChange={onChange} expanded={open}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Box
          width={'100%'}
          alignItems={'center'}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <Typography variant={'body1'}>{component?.component}</Typography>
          <Box>
            {sortable && (
              <>
                <IconButton
                  onClick={createHandleMoveComponent(path, 'up')}
                  size={'small'}
                >
                  <ArrowUpward fontSize={'small'} />
                </IconButton>
                <IconButton
                  onClick={createHandleMoveComponent(path, 'down')}
                  size={'small'}
                >
                  <ArrowDownward fontSize={'small'} />
                </IconButton>
              </>
            )}
            {deleteable && (
              <IconButton
                size={'small'}
                onClick={createHandleRemoveComponent(path)}
              >
                <Delete fontSize={'small'} />
              </IconButton>
            )}
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container={true} spacing={3}>
          {component?.attributes.map(({ name, ...rest }, i) => {
            const value = content[name];
            if (Array.isArray(value)) {
              const [subCollection, subName] = (
                (rest.component as string) || ''
              ).split('.');
              const subComponent = componentData?.components.find(
                (comp) =>
                  subName === comp.apiId && comp.category === subCollection
              );
              if (!subComponent) {
                return;
              }
              return (
                <Fragment key={`${subComponent?.component}-${i}`}>
                  {value.map((val, j) => {
                    return (
                      <Grid item={true} key={val.id || val.localId} xs={12}>
                        <AttributeField
                          path={`${path}.${name}.${j}`}
                          value={val as AttributeFieldProps['value']}
                          name={name}
                          {...rest}
                        />
                      </Grid>
                    );
                  })}
                  <Grid item={true} key={`${i}-add`} xs={12}>
                    <Button
                      endIcon={<Add />}
                      variant={'contained'}
                      color={'primary'}
                      onClick={createHandleInsertComponent(
                        `${path}.${name}.${value.length}`,
                        subComponent
                      )}
                      fullWidth={true}
                    >
                      Add new {subComponent?.component}
                    </Button>
                  </Grid>
                </Fragment>
              );
            }
            return (
              <Grid item={true} key={i} xs={12}>
                <AttributeField
                  path={`${path}.${name}`}
                  value={value as AttributeFieldProps['value']}
                  name={name}
                  {...rest}
                />
              </Grid>
            );
          })}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

interface AttributeFieldProps extends Omit<ComponentAttribute, '__typename'> {
  path: string;
  value: number | string | boolean | Record<string, unknown>;
}

const mapProps = (props: AttributeFieldProps): Record<string, unknown> => ({
  component: props.component,
  path: props.path,
  ...(props.value as Record<string, unknown>)
});

const AttributeField = (props: AttributeFieldProps) => {
  const { uploadFile, uploads } = useUploadsStore();
  const [markFileAsPublic, { data: markAsPublicData }] =
    useMarkFileAsPublicMutation();
  const [, { editComponent }] = usePageState();
  const [uploadId, setUploadId] = useState<string | null>(null);
  const { type, name, value, path, repeatable, required, min, max } = props;
  const [localValue, setLocalValue] = useState(value);
  const [fileInputRef, setFileInputRef] = useState<HTMLInputElement | null>(
    null
  );
  const handleEditComponent = useRef(
    debounce((path: string, value: string | number | boolean) => {
      editComponent(path, value);
    }, 250)
  );

  const upload = useMemo(() => {
    if (!uploadId) {
      return {} as Upload;
    }
    return uploads?.[uploadId] || {};
  }, [uploads, uploadId]);

  useEffect(() => {
    if (upload?.progress === 100) {
      if (fileInputRef) {
        fileInputRef.value = '';
      }
      if (!props?.allowedTypes?.includes(MP3) && upload.gsUri) {
        setLocalValue(upload.gsUri);
        handleEditComponent.current(path, upload.gsUri);
        return;
      }
      setTimeout(() => {
        if (!upload?.filename) {
          return;
        }
        markFileAsPublic({
          variables: {
            filename: upload.filename,
            purpose: FileUploadPurpose.Content
          }
        });
      }, 1000);
    }
  }, [fileInputRef, upload]);

  useEffect(() => {
    if (!markAsPublicData?.markFileAsPublic?.publicUrl) {
      return;
    }
    setLocalValue(markAsPublicData.markFileAsPublic.publicUrl);
    handleEditComponent.current(
      path,
      markAsPublicData.markFileAsPublic.publicUrl
    );
  }, [markAsPublicData]);

  const handleChange = useCallback(
    async (
      event:
        | ChangeEvent<
            HTMLInputElement | { name?: string | undefined; value: unknown }
          >
        | SelectChangeEvent<string>
    ) => {
      const evt = event as ChangeEvent<HTMLInputElement>;
      const val =
        evt.target?.type === 'checkbox'
          ? evt.target?.checked
          : evt.target.value;
      if (evt.target?.type === 'file' && evt.target.files?.[0]) {
        const id = await uploadFile({
          file: evt.target.files[0],
          purpose: FileUploadPurpose.Content
        });
        setUploadId(id);
        return;
      }
      setLocalValue(val);
      handleEditComponent.current(path, val);
    },
    [path]
  );

  const handleFileInputClick = () => {
    fileInputRef?.click();
  };

  switch (type) {
    case 'text': {
      return (
        <TextField
          multiline={true}
          fullWidth={true}
          onChange={handleChange}
          label={name}
          value={localValue || ''}
          required={required}
        />
      );
    }
    case 'enumeration': {
      return (
        <FormControl fullWidth={true}>
          <InputLabel>{name}</InputLabel>
          <Select value={(localValue as string) || ''} onChange={handleChange}>
            {props.enum?.map((val) => {
              return (
                <MenuItem key={val} value={val}>
                  {val}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      );
    }
    case 'boolean': {
      return (
        <FormControlLabel
          control={
            <Switch
              onChange={handleChange}
              checked={!!localValue}
              color='primary'
            />
          }
          label={name}
        />
      );
    }
    case 'component': {
      return (
        <ComponentEditor
          path={path}
          deleteable={!!repeatable}
          sortable={!!repeatable}
          content={mapProps(props)}
          open={true}
        />
      );
    }
    case 'integer': {
      return (
        <TextField
          onChange={handleChange}
          fullWidth={true}
          type={'number'}
          inputProps={{
            step: 1,
            min,
            max
          }}
          label={name}
          value={localValue || ''}
          required={required}
        />
      );
    }
    case 'decimal': {
      return (
        <TextField
          onChange={handleChange}
          fullWidth={true}
          type={'number'}
          inputProps={{
            step: 0.01,
            min,
            max
          }}
          label={name}
          value={localValue || ''}
          required={required}
        />
      );
    }
    case 'file': {
      return (
        <>
          <input
            type='file'
            onChange={handleChange}
            style={{ display: 'none' }}
            accept={props?.allowedTypes?.join(',')}
            ref={setFileInputRef}
          />
          <TextField
            fullWidth={true}
            inputProps={{
              readOnly: true
            }}
            label={name}
            onClick={handleFileInputClick}
            value={localValue || ''}
            required={required}
          />
        </>
      );
    }
    default: {
      return (
        <TextField
          onChange={handleChange}
          fullWidth={true}
          label={name}
          value={localValue || ''}
          required={required}
        />
      );
    }
  }
};
